import { mapGetters } from 'vuex';
import isNil from 'lodash/isNil';
import get from 'lodash/get';

import {
	RnButton,
	RnIcon,
	RnBatteryStatus,
	RnBatteryAttachments,
} from '@/components';
import { downloadAttachment } from '@/utils/download-attachment';
import { formatWeight } from '@/utils/units';
import { getBatteryStatusInfo } from '../services/new-battery';

const batteriesMixin = {
	components: {
		RnButton,
		RnIcon,
		RnBatteryStatus,
		RnBatteryAttachments,
	},
	data() {
		return {
			brand: null,
		};
	},
	computed: {
		...mapGetters(['brands', 'batteryModels', 'businessRelationId']),

		newBatteryTableHeaders() {
			return [
				{
					text: '',
					compact: true,
					sortable: false,
					component: RnButton,
					componentProps: battery => this.getDeleteButtonComponentProps(battery),
				},
				{
					text: '',
					compact: true,
					sortable: false,
					component: RnButton,
					componentProps: battery => this.getDuplicateButtonComponentProps(battery),
				},
				{
					text: '',
					compact: true,
					sortable: false,
					component: RnButton,
					componentProps: battery => this.getEditButtonComponentProps(battery),
				},
				this.statusBatteryHeader,
				{
					text: this.$t('addBattery.model'),
					compact: true,
					value: 'model',
					display: batteryModelId => {
						const model = this.batteryModels.find(m => m.batteryModelId === batteryModelId);

						return model ? model.name : batteryModelId;
					},
				},
				{
					text: this.$t('addBattery.partNumber'),
					compact: true,
					value: 'partNumber',
				},
				{
					text: this.$t('addBattery.year'),
					compact: true,
					value: 'constructionYear',
				},
				{
					text: this.$t('addBattery.formattedBatteryNumber'),
					compact: true,
					value: 'formattedBatteryNumber',
				},
				{
					text: this.$t('addBattery.serialNumber'),
					compact: true,
					value: 'serialNumber',
				},
				{
					text: this.$t('addBattery.internalReference'),
					compact: true,
					value: 'internalReference',
				},
				this.sizeBatteryHeader,
				this.weightBatteryHeader,
				this.attachmentBatteryHeader,
			];
		},
		existingBatteryTableHeaders() {
			return [
				this.transportationHeader,
				this.statusBatteryHeader,
				{
					text: this.$t('addBattery.model'),
					compact: true,
					value: 'model',
					display: batteryModelId => {
						const model = this.batteryModels.find(m => m.batteryModelId === batteryModelId);

						return model ? model.name : batteryModelId;
					},
				},
				{
					text: this.$t('addBattery.partNumber'),
					compact: true,
					value: 'partNumber',
				},
				{
					text: this.$t('addBattery.year'),
					value: 'constructionYear',
					compact: true,
				},
				{
					text: this.$t('addBattery.serialNumber'),
					value: 'serialNumber',
					compact: true,
				},
				this.sizeBatteryHeader,
				this.weightBatteryHeader,
			];
		},
			nestedBatteryTableHeaders() {
				return [
					this.transportationHeader,
					this.collectionStatusBatteryHeader,
					{
						text: this.$t('addBattery.model'),
						value: 'batteryDescription.batteryModel.name'
					},
					{
						text: this.$t('addBattery.partNumber'),
						value: 'batteryDescription.partNumber',
						compact: true,
					},
					{
						text: this.$t('addBattery.year'),
						value: 'batteryDescription.constructionYear',
						compact: true,
					},
					{
						text: this.$t('addBattery.formattedBatteryNumber'),
						compact: true,
						value: 'formattedBatteryNumber',
					},
					{
						text: this.$t('addBattery.serialNumber'),
						value: 'serialNumber',
						compact: true,
					},
					{
						text: this.$t('addBattery.internalReference'),
						compact: true,
						value: 'internalReference',
					},
					{
						text:	`${this.$t('common.width')} x ${this.$t('common.height')} x ${this.$t('common.depth')}`,
						value: "batteryDescription",
						compact: true,
						display: battery => {
							if (battery) {
								return `${get(battery, 'width')} x ${get(battery, 'height')} x ${get(battery, 'depth')} (${this.$t('common.mm')})`;
							} else {
								return '/';
							}
						},
					},
					this.weightBatteryHeader,
				];
			},

		editExistingBatteryTableHeaders() {
			return [
				...this.existingBatteryTableHeaders,
				{
					text: '',
					compact: true,
					component: RnButton,
					componentProps: value => this.getEditButtonComponentProps(value),
				},
			];
		},
		sizeBatteryHeader() {
			return {
				text:	`${this.$t('common.width')} x ${this.$t('common.height')} x ${this.$t('common.depth')}`,
				value: "batterySize",
				display: battery => {
					if (battery) {
						return `${get(battery, 'batteryDescription.width', get(battery, 'width'))} x ${get(battery, 'batteryDescription.height', get(battery, 'height'))} x ${get(battery, 'batteryDescription.depth', get(battery, 'depth'))} (${this.$t('common.mm')})`;
					} else {
						return '/';
					}
				},
			};
		},
		weightBatteryHeader() {
			return {
				text: this.$t('addBattery.weight'),
				value: "nettoWeight",
				compact: true,
				display: value => {
					if (value) {
						return formatWeight(value || battery.nettoWeight);
					} else {
						return '/';
					}
				},
			};
		},
		transportationHeader() {
			return {
				text: this.$t('common.transportation'),
				value: 'transportPackaging',
				component: RnIcon,
				componentProps: battery => {
					const transportPackagingId = get(battery, "transportPackagingId", null);
					let transportPackaging = get(battery, "transportPackaging", null)

					if (transportPackagingId) {
						let brand = null;

						if (battery.batteryDescription) {
							brand = battery.batteryDescription.brand.id;
						} else {
							brand = battery.brand;
						}

						const transportPackagings = this.$store.getters.getTransportPackagings(this.businessRelationId, brand);
						transportPackaging = transportPackagings.find((packaging) => packaging.id === transportPackagingId);
					}

					if (get(battery, "suitedForTransport", get(battery, "batteryPackaging.suitedForTransport", false))) {
						return {
							name: "info",
							label: get(transportPackaging, 'name', 'NA'),
							tooltip: {
								content: this.$t('common.suitedForTransport'),
							}
						}
					} else {
						return {
							label: get(transportPackaging, 'name', 'NA'),
						}
					}
				},
			};
		},
		statusBatteryHeader() {
			return {
				text: this.$t('addBattery.batteryStatus'),
				compact: true,
				sortable: false,
				component: RnBatteryStatus,
				componentProps: (battery) => this.getBatteryStatusProps(battery, this.businessRelationId, this.$store),
			};
		},
		collectionStatusBatteryHeader() {
			return {
				text: this.$t('addBattery.batteryStatus'),
				compact: true,
				sortable: false,
				component: RnBatteryStatus,
				componentProps: (battery) => this.getCollectionBatteryStatusProps(battery),
			};
		},
		brandBatteryTableHeader() {
			return {
				text: this.$t('addBattery.brand'),
				value: 'batteryDescription.brand.name',
			};
		},
		attachmentBatteryHeader() {
			return {
				text: '',
				sortable: false,
				compact: true,
				renderCondition: (battery) => battery.attachments && !!battery.attachments.length,
				component: RnBatteryAttachments,
				componentProps: (battery, rowIndex) => {
					const images = battery.attachments
						.filter(attachment => attachment.type === 'photo' || (attachment.name && (attachment.name.includes("jpg") || attachment.name.includes("png") || attachment.name.includes("jpeg"))))
						.map((attachment, index) => `
								<li
									attachment-id="${attachment.id || attachment.attachmentId}"
									battery-id="${rowIndex}"
									attachment-index="${index}"
									class="a-file-line"
								>
									${attachment.name || (attachment.fileInfo && attachment.fileInfo.fileName)}

									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="a-file-line__icon">
										<path d="M10.75 2.75a.75.75 0 00-1.5 0v8.614L6.295 8.235a.75.75 0 10-1.09 1.03l4.25 4.5a.75.75 0 001.09 0l4.25-4.5a.75.75 0 00-1.09-1.03l-2.955 3.129V2.75z" />
										<path d="M3.5 12.75a.75.75 0 00-1.5 0v2.5A2.75 2.75 0 004.75 18h10.5A2.75 2.75 0 0018 15.25v-2.5a.75.75 0 00-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5z" />
									</svg>
								</li>
						`)
						.join('');

					const statusReports = battery.attachments
						.filter(attachment => attachment.type === 'statusReport' || (attachment.name && (attachment.name.includes("pdf") || attachment.name.includes("doc"))))
						.map((attachment, index) => `
								<li
									attachment-id="${attachment.id || attachment.attachmentId}"
									battery-id="${rowIndex}"
									attachment-index="${index}"
									class="a-file-line"
								>
									${attachment.name || (attachment.fileInfo && attachment.fileInfo.fileName)}

									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="a-file-line__icon">
										<path d="M10.75 2.75a.75.75 0 00-1.5 0v8.614L6.295 8.235a.75.75 0 10-1.09 1.03l4.25 4.5a.75.75 0 001.09 0l4.25-4.5a.75.75 0 00-1.09-1.03l-2.955 3.129V2.75z" />
										<path d="M3.5 12.75a.75.75 0 00-1.5 0v2.5A2.75 2.75 0 004.75 18h10.5A2.75 2.75 0 0018 15.25v-2.5a.75.75 0 00-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5z" />
									</svg>
								</li>
						`)
						.join('');

					// TODO: return proper data for each file type
					return {
						pdf: !!statusReports.length ? {
							tooltip: {
								content: statusReports,
								autoHide: false,
							},
						} : null,
						image: !!images.length ? {
							tooltip: {
								content: images,
								autoHide: false,
							},
						} : null,
					};
				},
			};
		},
	},
	watch: {
		async batteryFormModel(newBatteryFormModel) {
			if (newBatteryFormModel.brand && this.brand !== newBatteryFormModel.brand) {
				await this.$store.dispatch('getBatteryPackagings', {
					businessRelationId: this.businessRelationId,
					brandId: newBatteryFormModel.brand,
				});
				await this.$store.dispatch('getTransportPackagings', {
					businessRelationId: this.businessRelationId,
					brandId: newBatteryFormModel.brand,
				});
			}

			this.brand = newBatteryFormModel.brand;
		},
	},
	created() {
		this.$store.dispatch('getAllBrands', this.businessRelationId);

		// We need to react to a tooltip content being clicked, but we can't add any view click handlers to an html string
		// So we watch click events on the document and filer the ones we need by the attachment-id attribute
		document.addEventListener('click', this.downloadAttachmentById);
	},
	beforeDestroy() {
		document.removeEventListener('click', this.downloadAttachmentById);
	},
	methods: {
		downloadAttachmentById(evt) {
			const attachmentId = evt.target.getAttribute('attachment-id');
			const attachmentIndex = evt.target.getAttribute('attachment-index');
			const batteryIndex = evt.target.getAttribute('battery-id');
			if (attachmentId) {
				// Clicked on attachment item => download attachment
				downloadAttachment(attachmentId, 'message.deliveryOrder.error', this.$store);
			} else if (!isNil(attachmentIndex) && !isNil(batteryIndex)) {
				// New battery doesn't have attachment ids, but instead the attachment fileInfos
				const fileInfo = get(this, `batteries[${batteryIndex}].attachments[${attachmentIndex}]`);
				if (fileInfo) {
					downloadAttachment(fileInfo, 'message.deliveryOrder.error', this.$store);
				}
			}
		},
		getEditButtonComponentProps(battery) {
			return {
				icon: {
					name: 'pencil-alt',
					width: '14px',
					height: '14px',
				},
				color: 'blue',
				outline: true,
				round: true,
				title: this.$t('common.edit'),
				method: () => {
					this.editBattery(battery);
				},
			};
		},
		getDuplicateButtonComponentProps(battery) {
			return {
				icon: {
					name: 'duplicate',
					width: '16px',
					height: '16px',
				},
				color: 'blue',
				outline: true,
				round: true,
				title: this.$t('common.duplicate'),
				method: () => {
					this.duplicateBattery(battery);
				},
			};
		},
		getDeleteButtonComponentProps(battery) {
			return {
				icon: {
					name: 'cross',
					width: '16px',
					height: '16px',
				},
				color: 'grey',
				outline: false,
				round: true,
				title: this.$t('common.delete'),
				method: () => {
					this.deleteBattery(battery);
				},
			};
		},
		getBatteryStatusProps(battery, businessRelationId, $store) {
			// Packaged battery component props
			let brand = null;

			if (battery.batteryDescription) {
				brand = battery.batteryDescription.brand.id;
			} else {
				brand = battery.brand;
			}

			$store.dispatch('getBatteryPackagings', {
				businessRelationId,
				brandId: brand,
			});

			const batteryPackagings = $store.getters.getBatteryPackagings(businessRelationId, brand);

			let packagingIcon = 'not-packaged';
			let packagingText = this.$t('common.notPackaged');
			// battery before it was posted to the api
			if (battery.batteryPackagingId) {
				const currentPackaging = (batteryPackagings || []).find(r => r.id === battery.batteryPackagingId);

				if (currentPackaging) {
					packagingIcon = get(currentPackaging, 'icon.name', 'custom-package');
					packagingText = get(currentPackaging, 'name');
				}
			}

			return {
				batteryProps: {
					icon: battery.batteryCondition ? battery.batteryCondition.iconName : "battery-ok",
					tooltip: battery.batteryCondition ? battery.batteryCondition.name : this.$t('common.notDamaged'),
				},
				packageProps: {
					icon: battery.batteryPackagingId ? packagingIcon : 'not-packaged',
					tooltip: battery.batteryPackagingId ? packagingText : this.$t('common.notPackaged'),
				}
			};
		},
		getCollectionBatteryStatusProps(battery) {
			let packagingIcon = 'not-packaged';
			let packagingText = this.$t('common.notPackaged');
			// battery before it was posted to the api
			if (battery.batteryPackaging && battery.batteryPackaging.id) {
				packagingIcon = get(battery.batteryPackaging, 'icon.name', 'custom-package');
				packagingText = get(battery.batteryPackaging, 'name');
			}

			let icon = battery.batteryCondition ? battery.batteryCondition.iconName : null;
			if (!icon && battery.batteryCondition) {
				icon = get(getBatteryStatusInfo(this.$t).find(status => status.id === battery.batteryCondition.id), 'iconName', 'battery-ok');
			}

			return {
				batteryProps: {
					icon: icon || "battery-ok",
					tooltip: battery.batteryCondition ? battery.batteryCondition.name : this.$t('common.notDamaged'),
				},
				packageProps: {
					icon: get(battery, "batteryPackaging.id", false) ? packagingIcon : 'not-packaged',
					tooltip: get(battery, "batteryPackaging.id", false) ? packagingText : this.$t('common.notPackaged'),
				}
			};
		}
	},
};

export default batteriesMixin;
