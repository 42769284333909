import store from '@/store';

export default function(to, from, next) {
	console.log(to.params);
	if (to.name === 'App') {
		next({ name: 'Dashboard', params: to.params });
	} else if (to.meta.allowedRoles) {
		// check if user role is in the allowed roles for this route
		if (store.getters.roles.find(role => !!~to.meta.allowedRoles.indexOf(role.name))) {
			next();
		} else {
			next({ name: 'Error', params: to.params });
		}
	} else {
		// no allowRoles are specified, so all roles are allowed access to this route
		next();
	}
}
