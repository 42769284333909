import get from 'lodash/get';
import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';

import RnIcon from '../icon/icon.vue';
import RnButton from '../button/button.vue';
import RnSidebar from '../sidebar/sidebar.vue';

export default {
	name: 'rn-new-table',
	components: {
		RnIcon,
		RnButton,
		RnSidebar,
	},
	props: {
		headers: {
			type: Array,
			default: () => [],
		},
		nestedHeaders: {
			type: Array,
			default: () => [],
		},
		visibleHeaders: {
			type: Array,
			default: null,
		},
		data: {
			type: Array,
			default: () => [],
		},
		collapsible: {
			type: Boolean,
			default: false,
		},
		detailLabel: {
			type: String,
			default: "",
		},
		withColSelection: {
			type: Boolean,
			default: false,
		},
		nestedPath: {
			type: String,
			default: "",
		},
	},
	mounted() {
		const element = document.querySelector('.v-data-table__wrapper');

		if (!element) {
			return;
		}

		this.setClasses();

		window.addEventListener('resize', debounce(this.setClasses, 300));

		element.addEventListener('scroll', throttle(this.setClasses, 100, {
			trailing: true,
		}));
	},
	unmounted() {
		window.removeEventListener('resize', debounce(this.setClasses, 300));
	},
	data() {
		return {
			expanded: [],
			scroll: {
				left: false,
				right: false,
			},
			selectedRow: null,
			isSidebarOpen: false,
		};
	},
	computed: {
		filteredHeaders() {
			return this.visibleHeaders ? this.headers.filter(header => this.visibleHeaders.includes(header.value)) : this.headers;
		},
		details() {
			return get(this.selectedRow, this.nestedPath);
		},
	},
	methods: {
		clickRow(item) {
			if (!this.isSidebarOpen) {
				this.isSidebarOpen = true;
			}

			this.selectedRow = item;

			this.$emit('row:click', item);
		},
		getValue(item, header) {
			const value = get(item, header.value);

			return header.display ? header.display(value) : (value || "-");
		},
		customSort(items, _sortBy, _sortDesc) {
			const sortBy = _sortBy[0];
			const sortDesc = _sortDesc[0];

			const header = this.headers.find(header => header.value === sortBy);

			items.sort((a, b) => {
				const aValue = get(a, sortBy);
				const bValue = get(b, sortBy);

				if (header && header.customSort === "date") {
					if (!sortDesc) {
						return new Date(aValue).getTime() - new Date(bValue).getTime();
					} else {
						return new Date(bValue).getTime() - new Date(aValue).getTime();
					}
				} else if (header && typeof header.customSort === "function") {
					const aFnValue = header.customSort(aValue);
					const bFnValue = header.customSort(bValue);

					if (!sortDesc) {
						return (aFnValue < bFnValue) ? -1 : 1;
					} else {
						return (bFnValue < aFnValue) ? -1 : 1;
					}
				} else {
					if (!sortDesc) {
						return (aValue < bValue) ? -1 : 1;
					} else {
						return (bValue < aValue) ? -1 : 1;
					}
				}
			});

			return items;
		},
		setClasses() {
			const el = document.querySelector('.v-data-table__wrapper');

			if (!el) {
				return;
			}

			// We just need to find out which direction it is overflowing to (can be both).
			// One pixel is added to the width to account for non-integer widths.
			const isScrolledToRight = el.scrollWidth < el.clientWidth + el.scrollLeft + 1;
			const isScrolledToLeft =  el.scrollLeft === 0;

			if (isScrolledToRight) {
				el.classList.remove('is-right-overflowing');

				this.scroll = {
					...this.scroll,
					right: false,
				}
			} else {
				el.classList.add('is-right-overflowing');

				this.scroll = {
					...this.scroll,
					right: true,
				}
			}

			if (isScrolledToLeft) {
				el.classList.remove('is-left-overflowing');

				this.scroll = {
					...this.scroll,
					left: false,
				}
			} else {
				el.classList.add('is-left-overflowing');

				this.scroll = {
					...this.scroll,
					left: true,
				}
			}
		},
		scrollRight() {
			const el = document.querySelector('.v-data-table__wrapper');
			el.scrollLeft += 150;
		},
		scrollLeft() {
			const el = document.querySelector('.v-data-table__wrapper');
			el.scrollLeft -= 150;
		},
	},
};
