import { mapGetters } from 'vuex';

import { TagColors } from '@/components/tag/tag.enums';
import { RnTag, RnNewTable, RnButton, RnActionHeader, RnSpinner, RnPagination, RnField, RnFilters } from '@/components';
import { batteriesMixin } from '@/mixins';
import { ITEMS_PER_PAGE } from '@/services/constants';
import { formatAsDate } from '@/utils/format-as-date';

export default {
	name: 'rn-rc-collections-view',
	components: {
		RnNewTable,
		RnButton,
		RnActionHeader,
		RnSpinner,
		RnPagination,
		RnField,
		RnFilters,
	},
	mixins: [batteriesMixin],
	data() {
		return {
			ITEMS_PER_PAGE: ITEMS_PER_PAGE.DEFAULT,
			page: 0,
		};
	},
	computed: {
		...mapGetters(['incomingCollectionOrders', 'incomingCollectionOrdersCount', 'businessRelationId', 'locations', 'brands']),
		locationIds: () => (this.locations || []).map(location => location.id),
		tableHeaders() {
			return [
				{
					text: this.$t('table.order.status'),
					value: 'status',
					component: RnTag,
					compact: true,
					componentProps: value => ({
						text: this.$t(`table.order.status.${value.status}`),
						color: TagColors[value.status.toLowerCase()],
					}),
				},
				{
					text: this.$t('table.order.nr'),
					value: 'number',
					compact: true,
				},
				{
					text: this.$t('table.order.brand'),
					value: 'batteries[0].batteryDescription.brand.name',
				},
				{
					text: this.$t('table.order.collectedBy'),
					value: 'spoc.name',
					compact: true,
				},
				{
					text: this.$t('table.order.dateRequested'),
					value: 'orderCreationDate',
					display: formatAsDate,
					sort: 'date',
					compact: true,
				},
				{
					text: this.$t('table.order.dateCollection'),
					value: 'pickUpDate',
					sort: 'date',
					display: formatAsDate,
					compact: true,
				},
				{
					text: this.$t('common.location'),
					value: 'sourceLocation.name',
				},
				{
					text: this.$t('common.destination'),
					value: 'destinationLocation.name',
				},
				{
					text: this.$t('table.order.totalBatteries'),
					value: 'batteries',
					display: batteries => batteries.length,
					compact: true,
				},
				{
					text: '',
					component: RnButton,
					componentProps: this.getOrderButtonComponentProps,
					compact: true,
				},
			];
		},
		downloadBatteryHeaders() {
			return [...this.nestedBatteryTableHeaders, this.attachmentBatteryHeader];
		},
		collectionOrdersState() {
			if (!this.incomingCollectionOrders) {
				return 'loading';
			}
			if (this.incomingCollectionOrders.length) {
				return 'results';
			}
			return 'noResults';
		},
	},
	created() {
		this.updateCollectionOrdersPaginated(0);
		this.$store.dispatch('getAllBrands', this.businessRelationId);
	},
	methods: {
		getOrderButtonComponentProps(collectionOrder) {
			const isBlocked = collectionOrder.status === 'Blocked';
			const isExecuted = collectionOrder.status === 'Executed';

			let text = this.$t('rc.deliveries.checkOrder');
			if (isBlocked) {
				text = this.$t('rc.deliveries.editReport');
			} else if (isExecuted) {
				text = this.$t('rc.deliveries.viewOrder');
			}

			return {
				text,
				color: 'blue',
				size: 'x-small',
				outline: true,
				disableWrap: true,
				icon: isBlocked ? { name: 'pencil-alt', width: '16px', height: '16px' } : undefined,
				method: evt => {
					evt.stopPropagation();
					if (isBlocked) {
						// TODO show remarks modal (RENEO-273)
					} else {
						this.$router.push({ name: 'RCOrderChecking', params: { id: collectionOrder.id } });
					}
				},
			};
		},
		updateCollectionOrdersPaginated(page) {
			this.page = page;
			const filters = {
				businessRelationId: this.businessRelationId,
				page: page || 0,
				pageSize: this.ITEMS_PER_PAGE,
			};

			this.$store.dispatch('getIncomingCollectionOrders', filters);
		},
	},
};
